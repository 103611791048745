<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form class="form-group">
                  <h1>{{ $t('SignInToYourAccount') }}</h1>
                  <div class="mb-3">
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-input-group-text>
                          <i class="icon-user"/>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        type="text"
                        class="form-control"
                        placeholder="Username"
                        v-model="$v.form.username.$model"
                      />
                    </b-input-group>
                    <div v-if="$v.form.username.$invalid && $v.form.username.$dirty">
                      <div class="error" v-if="!$v.form.username.required">{{ $t('usernameRequired') }}</div>
                      <div class="error" v-if="!$v.form.username.maxLength">{{ $t('usernameMaxLength') }}</div>
                    </div>
                  </div>
                  <div class="mb-4">
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-input-group-text>
                          <i class="icon-lock"/>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        autocomplete="current-password"
                        v-model="$v.form.password.$model"
                      />
                    </b-input-group>
                    <div v-if="$v.form.password.$invalid && $v.form.password.$dirty">
                      <div class="error" v-if="!$v.form.password.required">{{ $t('passwordRequired') }}</div>
                      <div class="error" v-if="!$v.form.password.maxLength">{{ $t('passwordMaxLength') }}</div>
                    </div>
                    <div v-if="error">
                      <div class="error">{{ $t('wrongLoginOrPassword') }}.</div>
                    </div>
                  </div>
                  <b-row>
                    <b-col cols="6">
                      <b-button
                        variant="primary"
                        class="px-4"
                        :disabled="$v.$dirty && $v.$invalid"
                        @click.prevent="submit"
                      >
                        {{ $t('Login') }}
                      </b-button>
                    </b-col>
                    <b-col cols="6" class="text-right">
<!--                      <b-button variant="link" class="px-0">Forgot password?</b-button>-->
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
<!--            <b-card no-body class="text-white bg-primary py-5 d-md-down-none" style="width:44%">-->
<!--              <b-card-body class="text-center">-->
<!--                <div>-->
<!--                  <h2>Sign up</h2>-->
<!--                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
<!--                  <b-button variant="primary" class="active mt-3">Register Now!</b-button>-->
<!--                </div>-->
<!--              </b-card-body>-->
<!--            </b-card>-->
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {APIService} from "../../services/api";

export default {
  name: 'Login',
  data: () => {
    return {
      form: {
        username: '',
        password: '',
      },
      error: false,
    }
  },
  validations: {
    form: {
      username: {
        required,
        maxLength: 256,
      },
      password: {
        required,
        maxLength: 256,
      },
    }
  },
  methods: {
    async submit() {
      this.error = false;
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const data = await APIService.post('auth/login', this.form);
          await localStorage.setItem('token', data.access_token);
          await APIService.setToken(data.access_token);
          await this.$router.push('/');
        } catch (e) {
          this.error = true;
        }
      }
    }
  }
}
</script>
